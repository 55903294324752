body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Lemonado';
  src: local('Lemonado'),
    url('./assets/fonts/Lemonado-Script-Textured-Italic.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Greed';
  src: url(./assets/fonts/greed-medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
